import styled from "styled-components"

export const ProductSelectContainer = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: flex;
    border: 1px solid ${({ theme }) => theme.mainLightColor};
    border-radius: 4px;
  }
`

export const ProductSelectOption = styled.div`
  margin-bottom: 12px;
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    width: 100%;
    margin: 0;
  }
`

export const ProductSelectRadio = styled.input`
  display: none;
`

export const ProductSelectTitle = styled.label`
  position: relative;
  padding-left: 26px;
  text-transform: capitalize;
  font-size: 14px;
  display: inline-block;

  &:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid ${({ theme }) => theme.borderDarkColor};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
  }

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    left: 7px;
    top: 7px;
    border-radius: 50%;
  }

  ${ProductSelectRadio}:checked ~ &:before {
    background-color: ${({ theme }) => theme.mainLightColor};
    border-color: ${({ theme }) => theme.mainLightColor};
  }

  ${ProductSelectRadio}:checked ~ &:after {
    background-color: ${({ theme }) => theme.backgroundColor};
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: block;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
    color: ${({ theme }) => theme.mainLightColor};
    
    &:before, &:after {
      content: none;
    }
    
    ${ProductSelectRadio}:checked ~ & {
      background-color: ${({ theme }) => theme.mainLightColor};
      color: ${({ theme }) => theme.backgroundColor};
    }
  }
`
