import React, { useState } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import CustomVoiceSearch from "./customVoiceSearch"
import {
  SearchButtonClear,
  SearchControls,
  SearchField,
  SearchForm,
  SearchFormContainer,
  SearchSubmitButton,
} from "./input.styled"
import theme from "./search.theme"

export default connectSearchBox(
  ({
    currentRefinement,
    refine,
    onSubmit,
    displaySearchResults,
    inputPlaceholder,
    onVoiceSearchFinished,
    searchTitle,
    copySearchUrlButton,
    isSearchPage
   }) => {
    const [voiceSearchActive, setVoiceSearchActive] = useState(false)
    const inputFilled = currentRefinement.length !== 0

    const onVoiceSearch = (voiceSearchState, isListening) => {
      setVoiceSearchActive(isListening)
      displaySearchResults()
      const { status, transcript } = voiceSearchState
      if (
        status === "finished" &&
        window.innerWidth < theme.screenWidthBreakpoint
      ) {
        onVoiceSearchFinished(transcript)
      }
    }

    return (
      <SearchFormContainer isSearchPage={isSearchPage} >
        {searchTitle}
        <SearchForm
          id="ep-doc-search-form"
          noValidate
          action=""
          role="search"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <SearchField
            id="ep-doc-search-field"
            type="text"
            voiceSearchActive={voiceSearchActive}
            value={currentRefinement}
            aria-label="Search"
            onChange={e => {
              refine(e.currentTarget.value)
            }}
            onFocus={displaySearchResults}
            placeholder={voiceSearchActive ? "Listening..." : inputPlaceholder}
          />
          <SearchControls>
            <div>
              {inputFilled && (
                <SearchButtonClear
                  id="search-cancel-button"
                  type="button"
                  onClick={() => {
                    refine("")
                  }}
                />
              )}
            </div>
            <div>
              <CustomVoiceSearch onChange={onVoiceSearch} />
            </div>
            <SearchSubmitButton type="submit" />
          </SearchControls>
        </SearchForm>
        {copySearchUrlButton}
      </SearchFormContainer>
    )
  }
)
