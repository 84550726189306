import React from "react"
import {
  ProductSelectContainer,
  ProductSelectOption,
  ProductSelectRadio,
  ProductSelectTitle,
} from "./productSelect.styled"


export const ProductSelect = ({ searchConfig, currentConfig, setConfig }) => {
  return (
    <ProductSelectContainer>
      {searchConfig.nodes.map(node => (
        <ProductSelectOption key={node.productId}>
          <ProductSelectRadio
            id={node.productId}
            type="radio"
            value={node.algolia.indexName}
            name="product-select"
            checked={node.productId === currentConfig.productId}
            onChange={() => {
              setConfig(node)
            }}
          />
          <ProductSelectTitle htmlFor={node.productId}>
            {node.productId.replace("-", " ")}
          </ProductSelectTitle>
        </ProductSelectOption>
      ))}
    </ProductSelectContainer>
  )
}
