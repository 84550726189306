import styled, { css } from "styled-components"

const poweredByStyle = () => css`
  .ais-PoweredBy {
    text-align: right;
    margin-right: 1em;
    margin-bottom: 6px;
    font-size: 0.8rem !important;
  }

  .ais-PoweredBy-logo {
    width: 60px;
    margin-left: 5px;
    margin-bottom: -8px;
  }
`

export const SearchTitle = styled.span`
  font-size: 24px;
  line-height: 50px;
  float: left;
  grid-column: 1;

  display: ${({ show }) => (show ? `initial` : `none`)};

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: none;
  }
`

export const HitsWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.backgroundColor};
  border: 2px solid ${({ theme }) => theme.borderLightColor};
  border-radius: 18px;
  z-index: 1;
  padding: 16px 36px 0 36px;
  right: 0;
  left: 0;
  top: 12px;
  display: ${props => (props.show ? `block` : `none`)};

  ${poweredByStyle};
`

export const HitPreview = styled.div`
  position: relative;

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: none;
  }
`

export const Separator = styled.hr`
  margin: 40px 0 25px 0;

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    margin-bottom: 0;
    margin-top: 20px;
  }
`

export const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 320px 8fr;
  grid-template-rows: 50px 1fr 50px;
  grid-gap: 20px;
  grid-template-areas:
    "facets         pagination-top"
    "facets         results"
    "pagination-bot pagination-bot";

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: block;
  }

    ${poweredByStyle};
`

export const SearchResultToolbarTop = styled.div`
  grid-area: pagination-top;
  justify-content: space-between;
  display: flex;

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: none;
  }
`

export const SearchResultToolbarBottom = styled.div`
  grid-area: pagination-bot;
  justify-content: flex-end;
  display: flex;

  .ais-PoweredBy {
    flex: 1;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 510px) {
    display: block;
    .ais-PoweredBy {
      display: block;
    }
  }

  .ais-Hits {
    padding: 10px 6px;
  }

  .ais-Snippet-nonHighlighted {
    font-weight: normal;
  }
`

export const FacetsContainer = styled.div`
  grid-area: facets;
  border: 1px solid ${({ theme }) => theme.borderLightColor};

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    border: none;
  }
`

export const ProductSelectCollapse = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    .collapse-title {
      display: none;
    }

    .collapse-children {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const facetsSlider = () => css`
  transition: 0.4s;
  transform: translateX(100%);
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 90%;
  margin-left: auto;
`

export const FacetsHeader = styled.div`
  display: none;
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    ${facetsSlider};
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.borderLightColor};
    align-items: center;
    flex-wrap: wrap;

    & > .clear-refinements {
      margin-left: 24px;
    }
  }
`

export const FilterContainer = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    ${facetsSlider};
    overflow-y: auto;
    height: 100%;
  }
`

export const FacetsFilter = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    background: transparent;
    transition: 0.8s;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    overflow-x: hidden;

    ${({ show }) =>
      show &&
      `
      display: block;
      background: rgba(0, 0, 0, 0.3);
      & > ${FilterContainer}, & > ${FacetsHeader} {
         transform: translateX(0);
      }
    `}
  }
`

export const FacetsModalToggleContainer = styled.div`
  display: none;

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 0 6px;
    margin-bottom: 30px;
  }
`

export const FacetsHeaderTitle = styled.span`
  margin: 0 20px;
  font-size: 14px;
  text-transform: uppercase;
`

export const FacetsModalToggleCount = styled.span`
  color: ${({ theme }) => theme.backgroundColor};
  width: 14px;
  height: 14px;
  display: inline-block;
  background-color: ${({ theme }) => theme.filterBadgeColor};
  border-radius: 50%;
  font-size: 11px;
  line-height: 14px;
  margin-right: 3px;
`

export const FacetsModalToggle = styled.button`
  border: 0;
  background: transparent;
  font-weight: bold;
  color: ${({ theme }) => theme.mainLightColor};

  &:focus {
    outline: none;
  }
`

export const FacetsHeaderClose = styled.button`
  all: initial;
  font-family: inherit;
  margin: 0 20px 0 auto;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.mainLightColor};
`

export const SeeAll = styled.button`
  all: initial;
  font-family: inherit;
  color: ${({ theme }) => theme.mainLightColor};
  cursor: pointer;
  font-size: 14px;
  padding: 5px 0;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`
export const FacetsHeaderLabel = styled.span`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.borderLightColor};
  text-align: center;
  width: 100%;
`
