import styled from "styled-components"

export const CollapseCheckbox = styled.input`
  display: none;
`

export const CollapseTitle = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.borderLightColor};
  
  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
  
  &:after {
    content: "";
    position: absolute;
    right: 30px;
    top: 30px;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(225deg);
    transition: 0.2s;
  }
  
  ${CollapseCheckbox}:checked ~ &:after {
    transform: rotate(45deg);
  }

`

export const CollapseChildren = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 20px;
  
  ${CollapseCheckbox}:checked ~ & {
    height: 0;
    padding: 0;
  }
`

export const CollapseContainer = styled.div`
  display: ${props => (props.show ? `block` : `none`)};
  ${({ disabled }) => disabled && `
    ${CollapseChildren} {
      height: 0;
      padding: 0;
    }
    
    ${CollapseTitle} {
      cursor: auto
    
      &:hover {
        background-color: inherit;
      }
      
      &:after {
        transform: rotate(45deg);
      }
    }
  `}
`
