import styled from "styled-components"

import nextPageButton from "../../images/nextpage-button.svg"

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-top: 7px;
  margin-left: auto;
  min-width: 360px;
`

export const PaginationCount = styled.span`
  line-height: 36px;
  vertical-align: top;
  height: 36px;
  color: ${({ theme }) => theme.textLightColor};
  font-size: 0.825em;
  margin: 0 20px;
`

export const PaginationShadowButton = styled.button`
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
  background: url(${nextPageButton});
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 8px 8px 0 rgba(0, 0, 0, 0.19);
  }

  &:active {
    box-shadow: 0 5px rgba(0, 0, 0, 0.24);
    transform: translateY(2px);
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    filter: grayscale(100%);
  }
`

export const PaginationButtonPrevious = styled(PaginationShadowButton)`
  transform: scaleX(-1);
`

export const PaginationButtonNext = styled(PaginationShadowButton)``
