import styled from "styled-components"

export const NoResult = styled.div`
  padding: 12px 0;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NoResultBold = styled.span`
  max-width: 100%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`
