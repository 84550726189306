import React, { useState } from "react"
import {
  createVoiceSearchHelper,
  connectSearchBox,
} from "react-instantsearch-dom"
import { SearchButtonRecord } from "./customVoiceSearch.styled"

export default connectSearchBox(({ refine, onChange }) => {
  const [isListening, setIsListening] = useState(false)

  let voiceSearchHelper

  if (typeof window !== `undefined`) {
    voiceSearchHelper = createVoiceSearchHelper({
      onQueryChange: query => refine(query),
      onStateChange: () => {
        const voiceSearchState = voiceSearchHelper.getState()
        const { status } = voiceSearchState
        const listening = !(status === "finished" || status === "error")
        setIsListening(listening)
        onChange(voiceSearchState, listening)
      },
    })
  }

  if (!voiceSearchHelper) return null

  const { isBrowserSupported, toggleListening } = voiceSearchHelper
  return (
    <>
      {isBrowserSupported() && (
        <SearchButtonRecord
          type="button"
          onClick={toggleListening}
          isListening={isListening}
        />
      )}
    </>
  )
})
