import React, { useEffect, useState } from "react"
import { connectMenu, connectRefinementList } from "react-instantsearch-dom"
import { graphql, StaticQuery } from "gatsby"
import { Collapse } from "./collapse"
import {
  FilterTitle,
  FilterContainer,
  FilterCount,
  FilterItem,
  FilterInput,
} from "./filter.styled"


const ComponentSelect = ({
  items,
  refine,
  onComponentChange,
  currentRefinement,
}) => {

  useEffect(() => {
    onComponentChange(items, currentRefinement)
  }, [onComponentChange, items, currentRefinement])

  return (
    <StaticQuery
      query={graphql`
        query RefinementListDocsRepolistQuery {
          allDocsRepolistJson {
            nodes {
              baseUrl
              algoliaIndice
              componentName
            }
          }
        }
      `}
      render={data => (
        <FilterContainer>
          {data.allDocsRepolistJson.nodes.map(node =>
            items.map(
              item =>
                "elasticpath_" + item.label === node.algoliaIndice && (
                  <FilterItem key={item.label}>
                    <FilterInput
                      id={node.componentName}
                      type="radio"
                      checked={item.isRefined}
                      name="tags"
                      onChange={() => {
                        refine(item.value)
                      }}
                    />
                    <FilterTitle type="radio" htmlFor={node.componentName}>
                      {node.componentName}
                    </FilterTitle>
                    <FilterCount>({item.count})</FilterCount>
                  </FilterItem>
                )
            )
          )}
        </FilterContainer>
      )}
    />
  )
}
const CustomComponentSelect = connectMenu(ComponentSelect)

const VersionSelect = ({
  items,
  refine,
  onVersionChange
}) => {

  useEffect(() => {
    onVersionChange(items)
  }, [onVersionChange, items])

  return (
    <FilterContainer>
      {items.map(item => (
        <FilterItem key={item.label}>
          <FilterInput
            type="radio"
            id={item.label}
            checked={item.isRefined}
            onChange={() => {
              refine(item.value)
            }}
          />
          <FilterTitle type="radio" className="filter-title" htmlFor={item.label}>
            {item.label}
          </FilterTitle>
        </FilterItem>
      ))}
    </FilterContainer>
  )
}

const CustomVersionSelect = connectMenu(VersionSelect)

const CategorySelect = ({
  items,
  refine,
  onCategoryChange
 }) => {

  useEffect(() => {
    onCategoryChange(items)
  }, [onCategoryChange, items])

  return(
    <FilterContainer>
      {items.map(item => (
        <FilterItem key={item.label}>
          <FilterInput
            type="checkbox"
            id={item.label}
            checked={item.isRefined}
            onChange={() => {
              refine(item.value)
            }}
          />
          <FilterTitle type="checkbox" className="filter-title" htmlFor={item.label}>
            {item.label}
          </FilterTitle>
          <FilterCount>({item.count})</FilterCount>
        </FilterItem>
      ))}
    </FilterContainer>
  )
}

const CustomCategorySelect = connectRefinementList(CategorySelect)

const compareCategoryLabels = (a, b) => {
  let labelA = a.label.toUpperCase()
  let labelB = b.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
}

export const Filter = () => {
  const [displayCategorySelect, setDisplayCategorySelect] = useState(true)
  const [displayRefinementList, setDisplayRefinementList] = useState(true)
  const [displayVersionSelect, setDisplayVersionSelect] = useState(true)
  const [disableVersionSelect, setDisableVersionSelect] = useState(false)

  const onVersionChange = items => {
    if (items && items.length) {
      setDisplayVersionSelect(true)
    } else {
      setDisplayVersionSelect(false)
    }
  }

  const onComponentChange = (items, currentRefinement) => {
    if (items && items.length) {
      setDisplayRefinementList(true)
    } else {
      setDisplayRefinementList(false)
    }

    setDisableVersionSelect(!Boolean(currentRefinement))
  }

  const onCategoryChange = items => {
    if (items && items.length) {
      setDisplayCategorySelect(true)
    } else {
      setDisplayCategorySelect(false)
    }
  }

  return (
    <FilterContainer>
      <Collapse
        title="Category"
        visible={displayCategorySelect}
      >
        <CustomCategorySelect
          attribute="hierarchy.lvl0"
          onCategoryChange={onCategoryChange}
          transformItems={items => items.sort(compareCategoryLabels)}
        />
      </Collapse>
      <Collapse
      title="Components"
      visible={displayRefinementList}
      >
        <CustomComponentSelect
          attribute="tags"
          onComponentChange={onComponentChange}
        />
      </Collapse>
      <Collapse
        title="Version"
        visible={displayVersionSelect && !disableVersionSelect}
      >
        <CustomVersionSelect
          attribute="version"
          transformItems={items => items.sort((a, b) => parseFloat(b.label) - parseFloat(a.label))}
          onVersionChange={onVersionChange}
        />
      </Collapse>
    </FilterContainer>
  )
}
