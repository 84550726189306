import styled from "styled-components"


export const ClearRefinementsButton = styled.button`
  font-size: 0.9em;
  font-weight: 600;
  color: ${({ theme }) => theme.mainColor};
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.backgroundColor};
  
  &:hover, &:active {
    text-decoration: underline;
  }
`
