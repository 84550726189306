import styled, { css } from "styled-components"

const Highlighted = () => css`
  background-color: ${({ theme }) => theme.resultHighlightColor};
  font-style: normal;
  font-weight: bold;
`

export const HitHighlighted = styled.span`
  ${Highlighted}
`

export const HitNormalText = styled.span`
  white-space: pre-wrap;
`

export const BlockResultSource = styled.span`
  height: 25px;
  font-size: 13px;
  padding: 0 10px;
  line-height: 25px;
  background-color: ${({ theme }) => theme.searchFieldColor};
  border: 1px solid ${({ theme }) => theme.hoverColor};
  background: ${({ theme }) => theme.backgroundColor};
  border-radius: 15px;
  margin-bottom: 4px;
  display: inline-flex;
`

export const BlockResultCategory = styled.div`
  margin: 14px 0;
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: start;
  grid-gap: 14px;
  align-items: center;
`

export const BlockResultLink = styled.div`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.mainLightColor};
`

export const BlockResultContent = styled.div`
  margin: 4px 0;
  color: ${({ theme }) => theme.textDarkColor};
  font-size: 0.875em;

  .ais-Snippet-highlighted {
    ${Highlighted}
  }
`

export const BlockResultContainer = styled.div`
  margin-bottom: 25px;
`

export const BlockResultTitle = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.textDarkColor};
  font-size: 14px;
  display: inline-flex;
  margin: 0 14px 4px 0;
`

export const LineHitLink = styled.a`
  color: ${({ theme }) => theme.textDarkColor};
  display: block;
  font-size: 14px;
  line-height: 24px;

  .ais-Snippet-nonHighlighted {
    font-style: normal;
    font-weight: normal;
  }

  .ais-Snippet-highlighted {
    font-style: normal;
    font-weight: bold;
    background-color: transparent;
  }
`

export const SeparatorLine= styled.hr`
  margin : 20px 0;
  height: 1px;
  border-width: 0;
  background-color: ${({ theme }) => theme.separatorLineColor};
`
