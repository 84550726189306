import styled from "styled-components"
import IconMicrophone from "../../images/ic-microphone.svg"
import IconMicrophoneWhite from "../../images/ic-microphone-white.svg"
import { SearchButton } from "./input.styled"

export const  SearchButtonRecord = styled.button`
  ${SearchButton};
  vertical-align: middle;
  background-image: url(${IconMicrophone});
  
  &:active {
    background-image: url(${IconMicrophoneWhite});
    background-color: ${({ theme }) => theme.mainColor};
  }
  
  ${({isListening, theme}) => isListening && `
    background-image: url(${IconMicrophoneWhite});
    background-color: ${theme.mainColor};
    
    &:hover {
      background-color: ${theme.mainColor};
    }
  `}
`
