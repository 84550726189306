import React from "react"
import { connectPagination, connectStats } from "react-instantsearch-dom"
import {
  PaginationButtonNext,
  PaginationButtonPrevious,
  PaginationContainer,
  PaginationCount
} from "./pagination.styled"

const Stats = ({ nbHits, resultStartOnPage, resultEndOnPage, pageSize }) => {
  if (nbHits > pageSize) {
    return (
      <PaginationCount>
        {resultStartOnPage} -{" "}
        {resultEndOnPage > nbHits ? nbHits : resultEndOnPage}&nbsp;{`of`}&nbsp;
        {nbHits}&nbsp;{"results"}{" "}
      </PaginationCount>
    )
  } else {
    return (
      <PaginationCount>
        {nbHits} result{nbHits > 1 ? "s" : ""}
      </PaginationCount>
    )
  }
}

const CustomStats = connectStats(Stats)

const Pagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
  pageSize,
}) => {
  const next = currentRefinement + 1
  const prev = currentRefinement - 1
  const start = (currentRefinement - 1) * pageSize + 1
  const end = (currentRefinement - 1) * pageSize + pageSize
  return (
    <PaginationContainer>
      <CustomStats
        resultStartOnPage={start}
        resultEndOnPage={end}
        pageSize={pageSize}
      />
      <div>
        {currentRefinement !== 1 ? (
          <a
            href={createURL(prev)}
            onClick={event => {
              event.preventDefault()
              refine(prev)
            }}
          >
            <PaginationButtonPrevious type="button" />
          </a>
        ) : (
          <PaginationButtonPrevious type="button" disabled />
        )}

        <PaginationCount>
          {`Page`}&nbsp;{nbPages > 0 ? currentRefinement : nbPages}&nbsp;{"of"}
          &nbsp;{nbPages}
        </PaginationCount>
        {currentRefinement !== nbPages && nbPages > 0 ? (
          <a
            href={createURL(next)}
            onClick={event => {
              event.preventDefault()
              refine(next)
            }}
          >
            <PaginationButtonNext type="button" />
          </a>
        ) : (
          <PaginationButtonNext type="button" disabled />
        )}
      </div>
    </PaginationContainer>
  )
}

export const PaginationUI = connectPagination(Pagination)
