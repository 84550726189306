import styled, { css } from "styled-components"


export const textStyle = () => css`
  font-size: 14px;
  padding-top: 2px;
  display: inline-block;
  margin-left: 6px;
`
export const customCheck = () => css`;
  border-bottom: 2.5px solid  ${({ theme }) =>theme.backgroundColor};
  border-right: 2.5px solid  ${({ theme }) =>theme.backgroundColor};
  display: inline-block;
  width: 5.5px;
  height: 11px;
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 1px;
 `
export const customDot = () => css`;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 7px;
  top: 7px;
  border-radius: 50%;
`

export const FilterContainer = styled.div``

export const FilterItem = styled.div`
  margin-bottom: 12px;
`

export const FilterInput = styled.input`
  display: none;
`

export const FilterTitle = styled.label`
  ${textStyle};
  padding-left: 26px;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid ${({ theme }) => theme.borderDarkColor};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: ${props => props.type === "checkbox" ? "4px" : "50%"};
  }

  &:after {
    content: "";
  }

  ${FilterInput}:checked ~ &:before {
    border: 1px solid ${({ theme }) => theme.mainLightColor};
    background-color: ${({ theme }) => theme.mainLightColor};
  }

  ${FilterInput}:checked ~ &:after {
    background-color: ${ props => (props.type === "radio" ? props.theme.backgroundColor : "")}
    ${(props)=> props.type === "checkbox" && customCheck}
    ${(props)=> props.type === "radio" && customDot}
  }
`

export const FilterCount = styled.span`
  ${textStyle}
`
