import React from "react"
import { connectCurrentRefinements } from "react-instantsearch-dom"
import { ClearRefinementsButton } from "./clearRefinement.styled"

const ClearRefinements = ({ items, refine }) => (
  <>
    {items.length ? (
      <ClearRefinementsButton
        onClick={() => {
          refine(items)
        }}
      >
        Clear Filters
      </ClearRefinementsButton>
    ) : null}
  </>
)

export const SearchClearRefinements = connectCurrentRefinements(
  ClearRefinements
)
