import React, { useEffect, useState } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import * as qs from "qs"
import { navigate } from "gatsby"

const createURL = ({ query, product, page, menu, refinementList }) => {
  const queryState = {
    query,
    page,
    product
  }

  if (menu && menu.version) {
    queryState.version = menu.version
  }

  if (menu && menu.tags) {
    queryState.tags = menu.tags
  }

  if (refinementList && refinementList["hierarchy.lvl0"]){
    queryState.category = refinementList["hierarchy.lvl0"]
  }

  return `?${qs.stringify(queryState)}`
}

const searchStateToUrl = (location, searchState) =>{
  if (searchState){
    if (process.env.GATSBY_PATH_PREFIX){
        return `${location.pathname.split(process.env.GATSBY_PATH_PREFIX)[1]}${createURL(searchState)}`
    } else {
        return `${location.pathname}${createURL(searchState)}`
    }
  } else {
      return ""
  }
}

const urlToSearchState = location => {
  if (location) {
    const { page, product, query, tags, version, category } = qs.parse(
      location.search.slice(1)
    )
    const state = {
      query,
      page: page || 1,
      product,
      refinementList: {}
    }

    if (version) {
      state.menu = { ...state.menu, version }
    }

    if (tags) {
      state.menu = { ...state.menu, tags }
    }

    if (category) {
      state.refinementList["hierarchy.lvl0"] = category
    }

    return state
  }
  return {}
}

const DEBOUNCE_TIME = 400

export const CustomInstantSearch = ({
  location,
  children,
  onSearchStateChange,
  productId,
  ...props
}) => {
  const state = urlToSearchState(location)
  const [searchState, setSearchState] = useState(state)
  const [debouncedSetState, setDebouncedSetState] = useState(null)

  useEffect(() => {
    if (location) {
      const state = urlToSearchState(location)
      onSearchStateChange(state)

      if (location.action !== "PUSH") {
        setSearchState(state)
      }
    }
  }, [location, onSearchStateChange])

  const handleSearchStateChange = searchState => {
    const updatedSearchState = { ...searchState, product: productId }

    onSearchStateChange(updatedSearchState)
    clearTimeout(debouncedSetState)

    if (productId === "commerce-cloud") {
      delete updatedSearchState.menu
    }

    if (productId === "commerce") {
      delete updatedSearchState.refinementList
    }

    if (searchState.menu && !searchState.menu.tags) {
      delete updatedSearchState.menu
    }

    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(location, updatedSearchState))
      }, DEBOUNCE_TIME)
    )

    setSearchState(updatedSearchState)
  }

  const searchProps = !location
    ? {
        onSearchStateChange,
      }
    : {
        searchState: searchState,
        onSearchStateChange: handleSearchStateChange,
        createURL: createURL,
      }

  return (
    <InstantSearch {...props} {...searchProps}>
      {children}
    </InstantSearch>
  )
}
