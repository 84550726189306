import React, { useEffect } from "react"
import { connectStateResults } from "react-instantsearch-dom"
import { NoResult, NoResultBold } from "./customStateResults.styled"

const StateResults = ({
  searchState,
  searchResults,
  hitComponent,
  onResultsChange = () => {},
}) => {

  useEffect(() => {
    onResultsChange(searchResults)
  }, [searchResults, onResultsChange])

  return searchResults && searchResults.nbHits !== 0 ? (
    <div>{searchResults.hits.map(hit => hitComponent(hit))}</div>
  ) : (
    <NoResult>
      <span>No results for "</span>
      <NoResultBold>{searchState.query}</NoResultBold>
      <span>"</span>
    </NoResult>
  )
}

export default connectStateResults(StateResults)
