import React, { useState } from "react"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ButtonIcon, CopyButton } from "./customCopyToClipboard.styled"
import CopyIcon from '../images/ic_copy.svg'
import CopyIconHover from '../images/ic_copy_hover.svg'
import CopyIconActive from '../images/ic_copy_active.svg'
import SuccessIcon from '../images/ic_success.svg'

const CustomCopyToClipboard = (props) => {
  const [isCopied, setIsCopied] = useState(false)
  const [label, setLabel] = useState(props.defaultLabel ? props.defaultLabel : 'Copy')
  const timer = () => {setTimeout(() => {
      setIsCopied(false)
      setLabel(props.defaultLabel ? props.defaultLabel : 'Copy')
    }, 2000 )
  }

  const setTimer = () => {
    setIsCopied(true)
    setLabel(props.notificationLabel ? props.notificationLabel : 'Copied!')
    timer()
  }

  return (
    <CopyToClipboard text={props.content} onCopy={setTimer}>
      <CopyButton
        isCopied={isCopied}
        defaultIconHover={props.defaultIconHover ? props.defaultIconHover: CopyIconHover}
        defaultIconActive={props.defaultIconActive ? props.defaultIconActive : CopyIconActive}
        notificationIcon={props.notificationIcon ? props.notificationIcon : SuccessIcon}
      >
        {label}
        <ButtonIcon
          isCopied={isCopied}
          defaultIcon={props.defaultIcon ? props.defaultIcon : CopyIcon}
          notificationIcon={props.notificationIcon ? props.notificationIcon : SuccessIcon}
        />
      </CopyButton>
    </CopyToClipboard>
  )
}

export default CustomCopyToClipboard;
