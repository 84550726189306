import styled from "styled-components"


export const ButtonIcon = styled.span`
  width: 32px;
  height: 32px;
  background-image:  ${ props => (props.isCopied ? `url(${props.notificationIcon})` : `url(${ props.defaultIcon })`)};
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
`

export const CopyButton = styled.button`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
  border: none;
  background-color: white;
  text-align: left;
  white-space: nowrap ;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) =>theme.mainColor};
  font-size: 16px;

  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:hover > ${ButtonIcon}{
    background-image: ${ props => (props.isCopied ? `url(${props.notificationIcon})` : `url(${props.defaultIconHover})`)};
  }
  &:active > ${ButtonIcon}{
    background-image:${ props => (props.isCopied ? `url(${props.notificationIcon})` : `url(${props.defaultIconActive})`)};
  }
`


