export default {
  mainColor: "#002080",
  mainLightColor: "#0033cc",
  backgroundColor: "#ffffff",
  textDarkColor: "#333333",
  textLightColor: "#777777",
  borderDarkColor: "#666666",
  borderLightColor: "#cccccc",
  hoverColor: "#E6EBFA",
  searchFieldColor: "#f7f7f7",
  resultColor: "#f2f6fa",
  resultHighlightColor: "rgba(0, 51, 204, 0.1)",
  filterBadgeColor: "#e77c00",
  screenWidthBreakpoint: 922,
  separatorLineColor: "#E2E7F5",
}
