import React, { useState, useRef, useEffect } from "react"
import { Configure, PoweredBy } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { navigate } from "gatsby"
import * as qs from "qs"
import { ThemeProvider } from "styled-components"
import SearchBox from "./input"
import { Filter } from "./filter"
import { PaginationUI } from "./pagination.js"
import { Collapse } from "./collapse"
import { ProductSelect } from "./productSelect"
import { SearchClearRefinements } from "./clearRefinement"
import { CustomInstantSearch } from "./customInstantSearch"
import { LineHit, BlockHit } from "./hit"
import CustomStateResults from "./customStateResults"
import {
  FacetsContainer,
  FacetsFilter,
  FacetsHeader,
  FacetsHeaderClose,
  FacetsHeaderTitle,
  FacetsModalToggle,
  FacetsModalToggleContainer,
  FacetsModalToggleCount,
  HitPreview,
  HitsWrapper,
  ProductSelectCollapse,
  ResultsContainer,
  SearchResultToolbarBottom,
  SearchResultToolbarTop,
  SearchTitle,
  Separator,
  FilterContainer,
  SeeAll,
  FacetsHeaderLabel,
} from "./search.styled"
import theme from "./search.theme"
import CustomCopyToClipboard from "../customCopyToClipboard"

const searchClient = config =>
  algoliasearch(config.algolia.appId, config.algolia.apiKey)

export default function Search({ searchConfig, location }) {
  const pageSize = 20
  const node = useRef()
  const [query, setQuery] = useState(``)
  const [filterVisible, setFilterVisible] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [hitsCount, setHitsCount] = useState(0)
  const [displaySearchResults, setDisplaySearchResults] = useState(false)

  const isSearchActive = query.length > 0 && displaySearchResults
  const isSearchPage = searchConfig.hasOwnProperty("nodes")

  const getLocationData = () => {
    return location ? qs.parse(location.search.slice(1)) : {}
  }

  const getConfig = () => {
    if (isSearchPage) {
      const locationData = getLocationData()
      const productConfig = searchConfig.nodes.find(
        node => node.productId === locationData.product
      )
      return productConfig || searchConfig.nodes[0]
    }

    return searchConfig
  }

  const [config, setConfig] = useState(getConfig())

  const handleClickOutside = e => {
    if (
      node.current.contains(e.target) ||
      e.target.closest("#ep-doc-search-form")
    ) {
      // inside click
      return
    }
    // outside click
    setDisplaySearchResults(false)
  }

  useEffect(() => {
    if (displaySearchResults && !isSearchPage) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [displaySearchResults, isSearchPage])

  const getSearchLink = query =>
    `/search?query=${encodeURIComponent(query)}&product=${config.productId}`

  const handleSearchStateChange = state => {
    let count = 0

    if (state) {
      setQuery(state.query ? state.query : "")

      if (state.menu) {
        state.menu.version && (count += 1)
        state.menu.tags && (count += 1)
      }

      if (state.refinementList && state.refinementList["hierarchy.lvl0"]) {
        count += state.refinementList["hierarchy.lvl0"].length
      }
    }

    setFilterCount(count)
  }

  const handleResultsChange = results => {
    if (results) {
      setHitsCount(results.hits.length)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    navigate(getSearchLink(query))
  }

  const onVoiceSearchFinished = query => {
    navigate(getSearchLink(query))
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomInstantSearch
        searchClient={searchClient(config)}
        indexName={config.algolia.indexName}
        location={location}
        productId={config.productId}
        onSearchStateChange={handleSearchStateChange}
      >
        <SearchBox
          inputPlaceholder={config.algolia.placeholderText}
          onSubmit={onSubmit}
          displaySearchResults={() => setDisplaySearchResults(true)}
          onVoiceSearchFinished={onVoiceSearchFinished}
          searchTitle={
            <SearchTitle show={isSearchPage}>Search</SearchTitle>
          }
          isSearchPage={isSearchPage}
          copySearchUrlButton={
            isSearchPage &&
            <CustomCopyToClipboard
              content={location.href}
              defaultLabel='Share results'
              notificationLabel='Copied URL'
            />
          }
        />
        {!isSearchPage && (
            <HitPreview>
              <HitsWrapper show={isSearchActive} ref={node}>
                <CustomStateResults
                  hitComponent={hit => LineHit(hit, config.productId)}
                />
                <SeeAll onClick={onSubmit}>See all</SeeAll>
                <PoweredBy />
              </HitsWrapper>
              <Configure
                hitsPerPage={5}
                typoTolerance={true}
                minWordSizefor1Typo={5}
                attributesToSnippet={"*:20"}
              />
            </HitPreview>
        )}
        {isSearchPage && (
          <>
            <Separator />
            <ResultsContainer>
              <SearchResultToolbarTop>
                <SearchClearRefinements />
                <PaginationUI class="active" pageSize={pageSize} />
              </SearchResultToolbarTop>
              <FacetsContainer>
                <ProductSelectCollapse>
                  <Collapse title="Product">
                    <ProductSelect
                      searchConfig={searchConfig}
                      setConfig={setConfig}
                      currentConfig={config}
                    />
                  </Collapse>
                </ProductSelectCollapse>
                <FacetsFilter show={filterVisible}>
                  <FacetsHeader>
                    <FacetsHeaderTitle>Filter</FacetsHeaderTitle>
                    <SearchClearRefinements />
                    <FacetsHeaderClose
                      onClick={() => setFilterVisible(!filterVisible)}
                    >
                      Close
                    </FacetsHeaderClose>
                    <FacetsHeaderLabel>
                      Filters are applied automatically
                    </FacetsHeaderLabel>
                  </FacetsHeader>
                  <FilterContainer>
                    <Filter />
                  </FilterContainer>
                </FacetsFilter>
              </FacetsContainer>
              <FacetsModalToggleContainer>
                <p>{hitsCount} results</p>
                {(
                  <FacetsModalToggle
                    onClick={() => setFilterVisible(!filterVisible)}
                  >
                    {filterCount > 0 ? (
                      <FacetsModalToggleCount>
                        {filterCount}
                      </FacetsModalToggleCount>
                    ) : (
                      ""
                    )}
                    Filter
                  </FacetsModalToggle>
                )}
              </FacetsModalToggleContainer>
              <CustomStateResults
                onResultsChange={handleResultsChange}
                hitComponent={BlockHit}
              />
              <SearchResultToolbarBottom>
                <PoweredBy />
                <PaginationUI class="active" pageSize={pageSize} />
              </SearchResultToolbarBottom>
            </ResultsContainer>
            <Configure
              hitsPerPage={pageSize}
              typoTolerance={true}
              minWordSizefor1Typo={5}
              distinct={0}
              attributesToSnippet={"*:20"}
            />
          </>
        )}
      </CustomInstantSearch>
    </ThemeProvider>
  )
}
