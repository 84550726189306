import React from "react"
import {
  CollapseCheckbox,
  CollapseChildren,
  CollapseContainer,
  CollapseTitle,
} from "./collapse.styled"

export const Collapse = ({
  title,
  children,
  visible = true,
}) => {
  return (
    <CollapseContainer show={visible}>
      <CollapseCheckbox type="checkbox" id={title} />
      <CollapseTitle className="collapse-title" htmlFor={title}>
        {title}
      </CollapseTitle>
      <CollapseChildren>{children}</CollapseChildren>
    </CollapseContainer>
  )
}
