import styled, { css } from "styled-components"

import IconSearch from "../../images/ic-search.svg"
import IconSearchWhite from "../../images/ic-search-white.svg"
import IconClear from "../../images/ic-clear.svg"
import IconClearWhite from "../../images/ic-clear-white.svg"

export const SearchFormContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isSearchPage }) => (isSearchPage ? `auto 1fr 160px` : `0 1fr 0`)};
  grid-gap: ${({ isSearchPage }) => (isSearchPage ? `25px` : `0`)};

  @media only screen and (max-width: ${({ theme }) => theme.screenWidthBreakpoint}px) {
    grid-template-columns: 0 1fr 0;
    grid-gap: 0;
    & > button {
      display: none;
    }
  }
`

export const  SearchForm = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  grid-column: 2;
  border-radius: 36px;
`

export const  SearchField = styled.input`
  height: 50px;
  padding: 0 110px 0 35px;
  border-radius: 36px;
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.textDarkColor};
  font-size: 14px;
  font-family: inherit;
  background-color: ${({ theme }) => theme.searchFieldColor};
  border: 1px solid ${({ theme }) => theme.borderLightColor};

  &:focus,
  &:not([value=""]) {
    outline: none;
    background-color: ${({ theme }) => theme.backgroundColor};
    border: 1px solid ${({ theme }) => theme.mainColor};
  }

  ${({ voiceSearchActive }) =>
  voiceSearchActive &&
  `
    outline: none;
    background-color: ${({ theme }) => theme.backgroundColor};
    border: 1px solid ${({ theme }) => theme.mainColor};

    &:placeholder {
      color: ${({ theme }) => theme.mainColor};
    }`}
`

export const  SearchSubmitButton = styled.button`
  border: none;
  flex: 0 0 64px;
  border-radius: 0 36px 36px 0;
  height: 50px;
  width: 64px;
  background-size: 14px 14px;
  background: url(${IconSearch}) no-repeat 18px center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  &:active,
  &:focus {
    outline: none;
  }
`

export const  SearchControls = styled.div`
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  ${SearchField}:focus ~ & > ${SearchSubmitButton} {
    background-color: ${({ theme }) => theme.mainColor};
    background-image: url(${IconSearchWhite});
  }
`

export const  SearchButton = () => css`
  all: initial;
  flex: 0 0 36px;
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 8px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  &:active {
    background-color: ${({ theme }) => theme.mainColor};
  }
`

export const  SearchButtonClear = styled.button`
  ${SearchButton};
  vertical-align: middle;
  background-image: url(${IconClear});

  &:active {
    background-image: url(${IconClearWhite});
  }
`
