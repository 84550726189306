import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { connectHighlight, Snippet } from "react-instantsearch-dom"
import {
  HitHighlighted,
  HitNormalText,
  BlockResultLink,
  BlockResultCategory,
  BlockResultContent,
  BlockResultSource,
  BlockResultTitle,
  BlockResultContainer,
  LineHitLink,
  SeparatorLine,
} from "./hit.styled"

const CustomHighlight = connectHighlight(
  ({ highlight, attribute, hit }) => {
    const highlights = highlight({
      highlightProperty: '_highlightResult',
      attribute,
      hit
    });

    return highlights.map((part, i) => part.isHighlighted ?
      (<HitHighlighted key={i + part.value}>{part.value}</HitHighlighted>) : (<HitNormalText key={i + part.value}>{part.value}</HitNormalText>)
    );
  }
);

const Source = ({ searchHitUrl }) => {
  const query = useStaticQuery(graphql`
    query DocsRepolistQuery1 {
      allDocsRepolistJson {
        nodes {
          baseUrl
          algoliaIndice
          componentName
        }
      }
    }
  `)

  const queryResults = query.allDocsRepolistJson.nodes
  //Split the URL of the search hit by slash (/)
  const splitHitUrl = searchHitUrl.split('/');

  /**
   * Detect version number from the search hit URL.
   * If the search hit was not for "latest" version,
   * the URL will contain version number. For example:
   *   - With version number: "https://domain/component-name/docs/2.0.x/index.html"
   *   - Without version number (latest): "https://domain/component-name/docs/index.html"
   */

  //component-name from the URL example above
  const componentNameInUrl = splitHitUrl[3]

  //Version number or path after component-name from the URL example above
  const versionInUrl = Number(splitHitUrl[5].split('.')[0])
  const version = Number.isInteger(versionInUrl) ? " V" + splitHitUrl[5] : "latest"

  const docsRepo = queryResults.filter(repo => repo.baseUrl === "/" + componentNameInUrl)

  return (
    <BlockResultSource>{docsRepo[0].componentName} {version}</BlockResultSource>
  )
}

export const BlockHit = hit => {
  return (
    <BlockResultContainer key={hit.objectID}>
      <BlockResultTitle>
        <CustomHighlight attribute="hierarchy.lvl0" hit={hit} />
      </BlockResultTitle>
      <Source searchHitUrl={hit.url} />
      <BlockResultLink>
        <a href={hit.url} target="_self">
          <CustomHighlight attribute="hierarchy.lvl1" hit={hit} />
          {hit.hierarchy.lvl2 !== null ? <> &nbsp;-&nbsp;<CustomHighlight attribute="hierarchy.lvl2" hit={hit} /></> : ""}
          {hit.hierarchy.lvl3 !== null ? <> &nbsp;-&nbsp;<CustomHighlight attribute="hierarchy.lvl3" hit={hit} /></> : ""}
          {hit.hierarchy.lvl4 !== null ? <> &nbsp;-&nbsp;<CustomHighlight attribute="hierarchy.lvl4" hit={hit} /></> : ""}
        </a>
      </BlockResultLink>
      <BlockResultContent>
        <Snippet attribute="content" hit={hit} />
      </BlockResultContent>
      <SeparatorLine/>
    </BlockResultContainer>
  )
}

export const LineHit = (hit, productId) => {
  const levels = Object.keys(hit.hierarchy).filter(
    lvl => hit.hierarchy[lvl] !== null
  )
  return (
    <BlockResultCategory key={hit.objectID}>
      <LineHitLink href={hit.url}>
        {levels.map((lvl, index, arr) => (
          <span key={index}>
            <Snippet hit={hit} attribute={`hierarchy.${lvl}`} />
            {index !== arr.length - 1 ? <span> - </span> : ""}
          </span>
        ))}
      </LineHitLink>
        {productId === "commerce" && (
          <Source searchHitUrl={hit.url} />
        )}
    </BlockResultCategory>
  )
}
